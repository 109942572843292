export const FA3AL = "فَعَلَ";
export const FA3UL = "فَعُلَ";
export const FA3IL = "فَعِلَ";
export const FA33AL = "فَعَّلَ";
export const FAA3AL = "فَاعَلَ";
export const AF3AL = "أَفْعَلَ";
export const TAF33AL = "تَفَعَّلَ";
export const TAFAA3AL = "تَفَاعَلَ";
export const INF3AL = "اِنْفَعَلَ";
export const IFTA3AL = "اِفْتَعَلَ";
export const IF3ALL = "اِفْعَلَّ";
export const ISTAF3AL = "اِسْتَفْعَلَ";
export const IF3AW3AL = "اِفْعَوْعَلَ";
export const IF3AALL = "اِفْعاَلَّ";
export const IF3AWL = "اِفْعَوَلَّ";
export const FA3LAL = "فَعْلَلَ";
export const TAFA3LAL = "تَفَعْلَلَ";

export const MAIN_WAZN_FOR_THULATHY = [
  FA3AL,
  FA3UL,
  FA3IL,
  FA33AL,
  FAA3AL,
  AF3AL,
  TAF33AL,
  TAFAA3AL,
  INF3AL,
  IFTA3AL,
  IF3ALL,
  ISTAF3AL,
  IF3AW3AL,
  IF3AALL,
  IF3AWL,
];

export const MAIN_WAZN_FOR_RUBA3Y = [FA3LAL, TAFA3LAL];
