import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import "./Header.css";
import { useTranslation } from "react-i18next";

function Header() {
  const { t } = useTranslation();
  return (
    <div className="header bg-white shadhow">
      <Navbar expand="md">
        <Container fluid>
          <Navbar.Brand href="#">
            <img src="./logo.png" className="logo" />
            <span className="h2 mx-0">{t("title")}</span>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
