import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import "./rootDerivations.css";
import RootInfoTable from "../table/table";
import AlertNotification from "../../../common/alarm/Alarm";
import { MAIN_WAZN_FOR_RUBA3Y, MAIN_WAZN_FOR_THULATHY } from "./constants";

const RootDerivationsForWazn = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [root, setRoot] = useState("");
  const [wazn, setWazn] = useState("");
  const [awzaanList, setAwzaanList] = useState([]);
  const [data, setData] = useState([]);
  const [isResolved, setIsResolved] = useState(false);

  const getResponse = async function (root: string, wazn: string) {
    await axios
      .get(
        `https://baleegh-service-57222993c7f8.herokuapp.com/arabic_word_process/build_root_derivations_for_wazn/${root}/${wazn}/`
      )
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
        setIsResolved(true);
      })
      .catch((err) => {
        setIsResolved(false);
        setIsLoading(false);
        console.log(err);
      });
  };

  const submit_root_wazn = (e) => {
    e.preventDefault();
    setData([]);
    setIsLoading(false);
    getResponse(root, wazn);
    setIsLoading(true);
    setIsResolved(true);
  };

  useEffect(() => {
    setRoot(root.replace(" ", ""));
    if (root.length === 3) {
      setAwzaanList(MAIN_WAZN_FOR_THULATHY);
    } else if (root.length === 4) {
      setAwzaanList(MAIN_WAZN_FOR_RUBA3Y);
    } else {
      setAwzaanList([]);
    }
  }, [root]);

  return (
    <Container className="pb-5 mx-5 px-0">
      <Row className="mx-0 px-0">
        <Col className="mt-5 mx-0 px-0">
          <h1 className="display-4 mx-0 px-0">
            {t("root_derviation.root_wazn_derviation_title")}
          </h1>
        </Col>
      </Row>
      <Row className="mx-0 px-0">
        <p className="mt-4 word-style mx-0 px-0">
          هذه الاداة تقوم ببناء جميع الاشتقاقات للجذر و الوزن المدخلين
        </p>
        <p className="word-style mx-0 px-0">ادخل جذر :</p>
      </Row>
      <Row className="mx-0 px-0">
        <Form className=" mb-3 mx-0 px-0" onSubmit={(e) => submit_root_wazn(e)}>
          <Row>
            <Col className="d-flex justify-content-start" md="3">
              <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                Name
              </Form.Label>
              <Form.Control
                onChange={(e) => {
                  setRoot(e.target.value);
                  setData([]);
                  setIsResolved(true);
                }}
                className="word-input px-3 m-0 word-style"
                id="inlineFormInput"
                placeholder={t("buildWord.add_root")}
              />
            </Col>
            {/* <Col md="3">
              <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                Name
              </Form.Label>
              <Form.Control
                onChange={(e) => {
                  setWazn(e.target.value);
                  setData([]);
                  setIsResolved(true);
                }}
                className="word-input word-style"
                id="inlineFormInput"
                placeholder={t("buildWord.add_wazn")}
              />
            </Col> */}
            <Col md="3">
              <Form.Select
                className="py-2 word-style"
                aria-label="ادخل الوزن"
                onChange={(e) => {
                  setWazn(e.target.value);
                  setData([]);
                  setIsResolved(true);
                }}
              >
                <option className="word-style">ادخل الوزن</option>
                {awzaanList.map((wazn, index) => (
                  <option
                    className="word-style"
                    key={`#/action-${index}`}
                    value={wazn}
                  >
                    {wazn}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          <Row className="mx-0 px-0">
            <Col className="mt-4 mb-3 mx-0 px-0">
              <Button
                type="submit"
                className="word-search-style px-4"
                variant="dark"
              >
                {t("buildWord.build_words")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
      {isLoading && (
        <Row className="my-5 mx-0 px-0">
          <Col className="d-flex justify-content-center">
            <Spinner />
          </Col>
        </Row>
      )}
      {!isResolved &&
        !isLoading &&
        root !== "" &&
        wazn !== "" &&
        data.length == 0 && (
          <AlertNotification
            alarmText={
              "تأكد ان الوزن مشكل , قد يكون الوزن غير صحيح او أن الجذر غير مضاف الى البرنامج بعد."
            }
          />
        )}
      {!isLoading && root !== "" && wazn !== "" && data.length !== 0 && (
        <>
          <p className="mt-4 h5">الجدول التالي يربط الوزن الصرفي مع الكلمة</p>
          <Row className="px-0 mx-0">
            <RootInfoTable data={data} />
          </Row>
        </>
      )}
    </Container>
  );
};

export default RootDerivationsForWazn;
