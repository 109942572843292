import { Route, Routes } from "react-router";
import { Container } from "react-bootstrap";
import Header from "./common/header/Header";
import Sidebar from "./common/sideBar/Sidebar";
import RootDerivations from "./rootDerviations/components/page/rootDerivations";
import RootDerivationsForWazn from "./rootToWazn/components/page/rootDerivationsToWazn";
import GetWazn from "./getWazn/components/page/getWazn";
import "./App.css";
import "./i8next/i18n";

function App() {
  return (
    <>
      <Sidebar />
      <Header />
      <Container className="my-5 px-0 w-75" fluid>
        <Routes>
          <Route path="/" element={<RootDerivationsForWazn />}></Route>
          <Route path="/root_derivations" element={<RootDerivations />}></Route>
          <Route path="/get_wazn" element={<GetWazn />}></Route>
        </Routes>
      </Container>
    </>
  );
}

export default App;
