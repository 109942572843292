import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import "./rootDerivations.css";
import AlertNotification from "../../../common/alarm/Alarm";

const RootDerivations = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [root, setRoot] = useState("");
  const [data, setData] = useState([]);
  const [isResolved, setIsResolved] = useState(false);

  const getResponse = async function (root) {
    await axios
      .get(
        `https://baleegh-service-57222993c7f8.herokuapp.com/arabic_word_process/build_root_derivations/${root}/`
      )
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
        setIsResolved(true);
      })
      .catch((err) => {
        setIsResolved(false);
        setIsLoading(false);
        console.log(err);
      });
  };

  const submit_root_wazn = (e) => {
    e.preventDefault();
    setData([]);
    setIsLoading(false);
    getResponse(root);
    setIsLoading(true);
    setIsResolved(false);
  };

  return (
    <Container className="pb-5 mx-5 px-0">
      <Row>
        <Col className="mt-5">
          <h1 className="display-4">
            {t("root_derviation.root_derviation_title")}
          </h1>
        </Col>
      </Row>
      <Row>
        <p className="mt-5 word-style">
          هذه الاداة تقوم ببناء جميع الاشتقاقات للجذر
        </p>
        <p className="word-style">ادخل جذر :</p>
      </Row>
      <Row>
        <Form className=" mb-3 mx-0 px-0" onSubmit={(e) => submit_root_wazn(e)}>
          <Row>
            <Col md="9">
              <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                Name
              </Form.Label>
              <Form.Control
                onChange={(e) => {
                  setRoot(e.target.value);
                  setData([]);
                  setIsResolved(true);
                }}
                className="word-input px-3 m-2 word-style"
                id="inlineFormInput"
                placeholder={t("buildWord.add_root")}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mt-4 mb-3 mx-2">
              <Button
                type="submit"
                className="pb-2 word-search-style px-4"
                variant="dark"
              >
                {t("buildWord.build_words")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
      {isLoading && (
        <Row className="my-5">
          <Col className="d-flex justify-content-center">
            <Spinner />
          </Col>
        </Row>
      )}
      {!isResolved && !isLoading && root !== "" && data.length == 0 && (
        <AlertNotification alarmText={".الجذر غير مضاف الى البرنامج بعد"} />
      )}
      {!isLoading && root !== "" && !!data && (
        <Row className="mx-0 px-0">
          <Col className="mx-0 px-0">
            {Array.from(data, (word: string) => (
              <span className="mx-1 word-style">{word} </span>
            ))}
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default RootDerivations;
