import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import "./getWazn.css";
import RootInfoTable from "../../../rootToWazn/components/table/table";
import AlertNotification from "../../../common/alarm/Alarm";

const GetWazn = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [word, setWord] = useState("");
  const [data, setData] = useState([]);
  const [isResolved, setIsResolved] = useState(false);

  const getResponse = async function (word: string) {
    await axios
      .get(
        `https://baleegh-service-57222993c7f8.herokuapp.com/arabic_word_process/get_wazn/${word}/`
      )
      .then((res) => {
        setData(res.data);
        setIsResolved(true);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsResolved(false);
        setIsLoading(false);
      });
  };

  const submit_root_wazn = (e) => {
    e.preventDefault();
    setData([]);
    setIsLoading(false);
    getResponse(word);
    setIsLoading(true);
    setIsResolved(true);
  };

  return (
    <>
      <Container className="pb-5 mx-5 px-0">
        <Row>
          <Col className="mt-5">
            <h1 className="display-4">أداة استخراج وزن الكلمة </h1>
          </Col>
        </Row>
        <Row>
          <p className="mt-5 word-style">
            هذه الاداة تقوم بإستخراج وزن الكلمة المدخلة
          </p>
          <p className="word-style">ادخل كلمة :</p>
        </Row>
        <Row>
          <Form
            className=" mb-3 mx-0 px-0"
            onSubmit={(e) => submit_root_wazn(e)}
          >
            <Row className="mx-0 px-0">
              <Col md="9">
                <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                  Name
                </Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setWord(e.target.value);
                    setData([]);
                    setIsResolved(true);
                  }}
                  className="word-input px-3 my-2 word-style"
                  id="inlineFormInput"
                  placeholder={t("buildWord.add_word")}
                />
              </Col>
            </Row>
            <Row className="mx-0 px-0">
              <Col className="my-3 mx-2 px-1">
                <Button
                  type="submit"
                  className="pb-2 word-search-style"
                  variant="dark"
                >
                  {t("buildWord.get_wazn_button")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>
        {isLoading && (
          <Row className="my-3 mx-0 px-0">
            <Col className="d-flex justify-content-center">
              <Spinner />
            </Col>
          </Row>
        )}
        {!isResolved && !isLoading && word !== "" && data.length === 0 && (
          <AlertNotification
            alarmText={"الكلمة لا تنطبق على الاوزان المناحة"}
          />
        )}

        {!isLoading && word !== "" && data.length !== 0 && (
          <>
            <p className="my-4 h5">الجدول التالي يربط الوزن الصرفي مع الكلمة</p>
            {data.map((waznInfo) => {
              return (
                <Row className="mx-0 px-0">
                  <RootInfoTable data={waznInfo} />
                </Row>
              );
            })}
          </>
        )}
      </Container>
    </>
  );
};

export default GetWazn;
