import { Table } from "react-bootstrap";
import "./table.css";

interface RootInfoTable {
  data: any;
}

const RootInfoTable = (props: RootInfoTable) => {
  const { data } = props;
  return (
    <Table bordered hover className="px-0 mx-0 w-50">
      <thead>
        <tr>
          <th className="table-td word-style-table">الوزن الصرفي</th>
          <th className="table-td word-style-table">الكلمة</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          Object.keys(data).map((key) => (
            <tr key={key}>
              <td className="table-td word-style-table">{key}</td>
              <td className="table-td word-style-table">
                {data[key].toString() === "" ? " - " : data[key].toString()}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default RootInfoTable;
